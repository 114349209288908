import { graphql } from 'gatsby';
import React from 'react';
import GeneralHead from '../components/generalHead';
import Layout from '../components/layout';
import SEO from '../components/seo';
import componentGenerator from '../utils/componentGenerator';

const CaseStudies = ({
  data: {
    caseStudiesPage: { components },
  },
}) => {
  const isDark =
    components &&
    ((components[1]?.background &&
      Boolean(components[1].background.toLowerCase() !== 'light')) ||
      components[1]?.__typename === 'DatoCmsHeroCarouselComponent');

  return (
    <Layout forceAnnouncement={components[0].announcement} isDark={isDark}>
      {componentGenerator(components, '/case-studies', undefined, isDark)}
    </Layout>
  );
};

export const Head = ({
  data: {
    caseStudiesPage: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query CaseStudiesPageQuery {
    caseStudiesPage: datoCmsCaseStudiesPage {
      components {
        __typename
        ... on DatoCmsComponentHero {
          ...datoCmsComponentHero
        }
        ... on DatoCmsHeroCarouselComponent {
          ...datoCmsHeroCarouselComponent
        }
        ... on DatoCmsBannerAdComponent {
          ...datoCmsBannerAdComponent
        }
        ... on DatoCmsGridComponent {
          ...datoCmsGridComponent
        }
        ... on DatoCmsConversionPanelComponent {
          ...datoCmsConversionPanelComponent
        }
        ... on DatoCmsMetricsComponent {
          ...datoCmsMetricsComponent
        }
        ... on DatoCmsSwitchbackComponent {
          ...datoCmsSwitchbackComponent
        }
        ... on DatoCmsHeaderComponent {
          ...datoCmsHeaderComponent
        }
        ... on DatoCmsFooterComponent {
          ...datoCmsFooterComponent
        }
        ... on DatoCmsCaseStudyListComponent {
          ...datoCmsCaseStudyListComponent
        }
      }
      indexable
      headHtml
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schemaMarkup {
        id
        schema
      }
    }
  }
`;

export default CaseStudies;
